<template>
  <div class="activity-list container">
    <div class="searchbox">
      <el-form inline>
        <el-form-item label="活动名称">
          <el-input placeholder="请输入活动名称"
                    v-model="searchForm.act_name"
                    @keyup.enter.native="searchBtn"
                    clearable
                    class="input-with-select">
            <!-- <el-button slot="append"
                      @click="getList"
                      icon="el-icon-search"></el-button> -->
          </el-input>
        </el-form-item>
        <el-button type="primary"
                   @click="getList">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form>
    </div>
    <div>
      <el-button type="primary"
                 v-if="module_id_array.includes('103') || module_id_array.includes('999')"
                 @click="toAddActivity">新增活动</el-button>
    </div>
    <div class="table-box">
      <el-table ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }">
        <el-table-column v-for="item in tableHeaders"
                         :key="item.prop"
                         :prop="item.prop"
                         :label="item.label"
                         :width="item.width"
                         :align="item.align"
                         :fixed="item.fixed">
          <template slot-scope="scope">
            <div v-if="item.prop == 'create_time'">
              {{ scope.row.create_time }}
            </div>
            <div v-else-if="item.prop == 'activity_time'">
              {{ scope.row.start_time }} - {{ scope.row.end_time }}
            </div>
            <!-- <div v-else-if="item.prop == 'act_status'">
              {{ scope.row.act_status | statusText}}
            </div> -->
            <div v-if="item.prop == 'act_status'">
              {{scope.row.act_status == 1 ? '开启' : '关闭'}}
            </div>
            <div v-else>{{ !scope.row[item.prop] ? '' : scope.row[item.prop] }}</div>
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="180">
          <template slot-scope="scope">
            <el-button type="text"
                       @click="toCheckActivity(scope.row)">查看</el-button>
            <el-button v-if="module_id_array.includes('103') && scope.row.status !== '已结束'"
                       type="text"
                       @click="toEditActivity(scope.row)">编辑</el-button>
            <el-button v-if="module_id_array.includes('102') && scope.row.status !== '已结束'"
                       type="text"
                       @click="changeStatus(scope.row)">{{scope.row.act_status == 1 ? '关闭' : '开启'}}</el-button>
            <!-- <el-switch v-if=" module_id_array.includes('102')"
                       v-model="scope.row.act_status"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="1"
                       :inactive-value="0"
                       @change="changeStatus(scope.row)">
            </el-switch> -->
            <!-- <el-button v-if="scope.row.act_status != 1 && module_id_array.includes('102')"
                       type="text"
                       size="medium"
                       class="red"
                       @click="changeStatus(scope.row,1)">启 用</el-button>
            <el-button v-if="scope.row.act_status == 1 && module_id_array.includes('102')"
                       type="text"
                       size="medium"
                       class="red"
                       @click="changeStatus(scope.row,0)">关 闭</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <pagination v-show="totalNum>0"
                :total="totalNum"
                :page.sync="pagination.page"
                :limit.sync="pagination.pageNumber"
                @pagination="getList" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Pagination from '@/components/Pagination/index';
import operation from '@/api/operation.js';
import commonUtil from '../../../utils/commonUtil.js';
export default {
  name: 'activityList',
  components: {
    Pagination
  },
  data() {
    return {
      module_id_array: window.utils.storage.getter('module_id_array') || [],
      totalNum: 0,
      pagination: {
        page: 1,
        pageNumber: 10
      },
      searchForm: {
        act_name: ''
      },
      tableHeaders: [
        {
          label: '活动名称',
          prop: 'act_name',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '创建时间',
          prop: 'create_time',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '活动时间',
          prop: 'activity_time',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        // {
        //   label: '订单实付金额',
        //   prop: 'activity_price',
        //   width: 'auto',
        //   align: 'center',
        //   fixed: false
        // },
        {
          label: '活动销售金额',
          prop: 'activity_price',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '付款人数',
          prop: 'userNum',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '活动订单数',
          prop: 'orderNum',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '活动状态',
          prop: 'status',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '活动开关',
          prop: 'act_status',
          width: 'auto',
          align: 'center',
          fixed: false
        }
      ],
      tableData: []
    };
  },
  filters: {
    dateFormat(val) {
      return !val ? '' : dayjs.unix(val).format('YYYY-MM-DD HH:mm');
    },
    statusText(val) {
      switch (val) {
        case 0:
          return '未开始';
        case 1:
          return '进行中';
      }
    }
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    reset() {
      commonUtil.lostFocus(event)
      this.pagination = {
        page: 1,
        pageNumber: 10
      }
      this.searchForm = {
        act_name: ''
      }
      this.getList();
    },
    getList() {
      let params = { ...this.pagination, ...this.searchForm };
      operation.getActivityLists(params).then((res) => {
        let { code, data } = res;
        if (code === 0) {
          this.totalNum = data.total;
          this.tableData = data.list;
        }
      });
    },
    searchBtn(e) {
      let keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.getList();
      }
    },
    toCheckActivity(row) {
      this.$router.push({
        path: '/operation/activityMgmt/addOrCheck',
        query: {
          act_id: row.act_id
        }
      });
    },
    toAddActivity(row) {
      this.$router.push({
        path: '/operation/activityMgmt/addOrEditor',
        query: {
          act_id: ''
        }
      });
    },
    toEditActivity(row) {
      this.$router.push({
        path: '/operation/activityMgmt/addOrEditor',
        query: {
          act_id: row.act_id
        }
      });
    },
    changeStatus(row) {
      let params = {
        act_id: row.act_id,
        act_status: row.act_status == 1 ? 0 : 1
      };
      operation.editActivityStatus(params).then((res) => {
        let { code } = res;
        if (code === 0) {
          this.$message.success('改变成功');
          this.getList();
        }
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.activity-list {
  .searchbox {
    .input-with-select {
      width: 240px;
      margin-left: 10px;
    }
  }
}
.dispaly-inline {
  display: inline-block;
}
</style>
